import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, QuestionnaireTypeDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-questionnaire-type-item-form',
  templateUrl: './questionnaire-type-item-form.component.html',
  styleUrl: './questionnaire-type-item-form.component.scss',
})
export class QuestionnaireTypeItemFormComponent extends BaseForm<QuestionnaireTypeDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
